import React from "react"
import Slider from "react-slick"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"
import { StaticImage } from "gatsby-plugin-image"

import { useTheme, makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
  slickDots: {
    position: "absolute",
    bottom: "-25px",
    display: "block",
    width: "100%",
    padding: 0,
    margin: 0,
    listStyle: "none",
    textAlign: "center",
    "& li": {
      position: "relative",
      display: "inline-block",
      width: 20,
      height: 20,
      margin: "0 5px",
      padding: 0,
      cursor: "pointer",
      "& .slick-active": {
        "& button": {
          "&::before": {
            opacity: 0.75,
            color: "black",
          },
        },
      },
      "& button": {
        fontSize: 0,
        lineHeight: 0,
        display: "block",
        width: 20,
        height: 20,
        padding: 5,
        cursor: "pointer",
        color: "transparent",
        border: 0,
        outline: "none",
        background: "transparent",
        "&::before": {
          fontFamily: "slick",
          fontSize: "6px",
          lineHeight: "20px",
          position: "absolute",
          top: 0,
          left: 0,
          width: 20,
          height: 20,
          content: "•",
          textAlign: "center",
          opacity: 0.25,
          color: "black",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },
        "&:hover": {
          outline: "none",
          "&::before": {
            opacity: 1,
          },
        },
        "&:focus": {
          outline: "none",
          "&::before": {
            opacity: 1,
          },
        },
      },
    },
  },
  slickThumb: {
    bottom: "-8.1025em",
    "& li": {
      width: "23%",
      height: "8.125em",
      "& img": {
        [theme.breakpoints.only("xs")]: {
          display: "none !important",
        },
        width: "100%",
        height: "100%",
        // maxHeight: "130px",
        filter: "grayscale(100%)",
        borderRadius: 10,
      },
    },
    "& li.slick-active": {
      "& img": {
        filter: "grayscale(0%)",
      },
    },
  },
  hr: {
    width: "10em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".35rem",
    // borderColor: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    marginTop: "1.5em",
    marginBottom: "4em",
  },
  smallhr: {
    width: "4.5em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".125rem",
    // borderColor: theme.palette.primary.main,
  },
  sectionTitle: {
    color: theme.palette.secondary.light,
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  crewImg: {
    display: "block",
    // maxWidth: "41rem",
    maxWidth: "60%",
    border: `4px solid ${theme.palette.secondary.main}`,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "3em",
    [theme.breakpoints.down("lg")]: {
      marginTop: "5em",
      maxWidth: "39em",
      border: `4px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "30rem",
      marginBottom: "4em",
      border: `4px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  sectionSubtitle: {
    fontFamily: "Open Sans, Work Sans, sans-serif",
    // color: theme.palette.common.muted,
    color: "#ebebf5",
    fontWeight: 400,
    fontSize: "1.02rem",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1,
  },
  slider: {
    "& .slick-arrow": {
      backgroundColor: theme.palette.primary.main,
      top: "35%",
      height: 60,
      width: 40,
      opacity: 0.7,
      zIndex: 5,
      "&:hover": {
        opacity: 1,
      },
    },
    "& .slick-prev": {
      left: "10px",
    },
    "& .slick-next": {
      right: "10px",
    },
    [theme.breakpoints.up("xl")]: {
      "& .slick-prev": {
        left: "59px",
      },
      "& .slick-next": {
        right: "58px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "& .slick-prev": {
        left: "14px",
      },
      "& .slick-next": {
        right: "13px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .slick-prev": {
        left: "20px",
      },
      "& .slick-next": {
        right: "20px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& .slick-prev": {
        left: "-1px",
      },
      "& .slick-next": {
        right: "0px",
      },
    },
  },
}))

export default function Products() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const settings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    className: `${classes.slider}`,
  }
  const smolSettings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: `${classes.slider}`,
  }

  const medSettings = {
    focusOnSelect: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    className: `${classes.slider}`,
  }

  let TeamMember = ({
    name = "Jaime",
    title = "Crewmember",
    description = "....",
    image = (
      <StaticImage
        src="../assets/images/Andreatta-Waterscapes-Jaime-Pond-Builders-Medford-Oregon.jpg"
        alt="Jaime"
        placeholder="blurred"
        height={310}
        width={310}
        imgStyle={{
          zIndex: "0",
        }}
      />
    ),
  }) => {
    return (
      <>
        <div
          style={{
            position: "relative",
            width: 310,
            height: 310,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Grid
            container
            justifyContent="center"
            style={{ position: "absolute", height: "100%" }}
          >
            <Grid item xs={12} style={{ zIndex: 2 }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item xs={12} style={{ color: "rgba(255,255,255,0.7)" }}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography
                      variant="h5"
                      align="center"
                      style={{
                        textTransform: "uppercase",
                        lineHeight: "1.2",
                        fontWeight: 900,
                      }}
                    >
                      {name}
                    </Typography>
                    <Grid item xs={12}>
                      <hr className={classes.smallhr} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.overlay} />
          {image}
        </div>
        <Grid
          item
          lg={9}
          md={8}
          sm={6}
          xs={10}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "1.5em",
          }}
        >
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Grid>
      </>
    )
  }

  return (
    <Grid
      container
      direction="row"
      id="team"
      justifyContent="center"
      style={{ padding: "6.25em 0" }}
    >
      <Grid item xs={12}>
        <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Typography
                variant="h3"
                align="center"
                gutterBottom
                className={classes.sectionTitle}
              >
                The Pond Guys
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                container
                lg={8}
                justifyContent="center"
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  gutterBottom
                  className={classes.sectionSubtitle}
                >
                  We’ve got a fantastic installation crew and we’re grateful for
                  the hard work and enthusiasm they bring to building quality
                  water features for our customers. These guys never thought
                  they would look for the ‘pretty side’ of a rock. Now, when it
                  comes to building one-of-a-kind, custom creations they pride
                  themselves in being artisans.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
            <Grid item xs={12}>
              <StaticImage
                src="../assets/images/Pond Guys Building_Andreatta Waterscapes_Grants Pass Oregon.jpg"
                alt="Lora Andreatta, owner of Andreatta Waterscapes"
                placeholder="blurred"
                layout="constrained"
                quality={60}
                className={classes.crewImg}
              />
            </Grid>
          </Grid>
          {/* {smDown ? (
            <Slider {...smolSettings}>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Jaime has been with Andreatta Waterscapes for almost ten years. He is a hard working man who always gets the job done! Rain, snow or sun, Jaime is there and ready to work.  You'll see Jaime building water features along side Tonja and if you're on our maintenance route, you'll see him and his rubber boots then too."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Jaime of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Alex"
                  description="Alex has been with Andreatta Waterscapes for eight years.  He takes pride in his work and makes sure that things get done right the first time.   Alex is also a fantastic pond builder and maintenance tech so keep an eye out for him when he stops by."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/Andreatta-Waterscapes-Alex-Pond-Builders-Medford-Oregon.jpg"
                      alt="Alex of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Lora is our office manager, marketing director and all around get it done girl.  You'll likely be talking to her when you call and she can answer all your questions!"
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Lora of Andreatta Waterscapes"
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
            </Slider>
          ) : matchesMD ? (
            <Slider {...medSettings}>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Jaime has been with Andreatta Waterscapes for almost ten years. He is a hard working man who always gets the job done! Rain, snow or sun, Jaime is there and ready to work.  You'll see Jaime building water features along side Tonja and if you're on our maintenance route, you'll see him and his rubber boots then too."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Jaime of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Alex"
                  description="Alex has been with Andreatta Waterscapes for eight years.  He takes pride in his work and makes sure that things get done right the first time.   Alex is also a fantastic pond builder and maintenance tech so keep an eye out for him when he stops by."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/Andreatta-Waterscapes-Alex-Pond-Builders-Medford-Oregon.jpg"
                      alt="Alex of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Lora is our office manager, marketing director and all around get it done girl.  You'll likely be talking to her when you call and she can answer all your questions!"
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Lora of Andreatta Waterscapes"
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                      }}
                    />
                  }
                />
              </div>
            </Slider>
          ) : (
            <Slider {...settings}>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Jaime has been with Andreatta Waterscapes for almost ten years. He is a hard working man who always gets the job done! Rain, snow or sun, Jaime is there and ready to work.  You'll see Jaime building water features along side Tonja and if you're on our maintenance route, you'll see him and his rubber boots then too."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Jaime of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                        border: `4px solid ${theme.palette.secondary.main}`,
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Alex"
                  description="Alex has been with Andreatta Waterscapes for eight years.  He takes pride in his work and makes sure that things get done right the first time.   Alex is also a fantastic pond builder and maintenance tech so keep an eye out for him when he stops by."
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/Andreatta-Waterscapes-Alex-Pond-Builders-Medford-Oregon.jpg"
                      alt="Alex of Andreatta Waterscapes"
                      transformOptions={{
                        cropFocus: "attention",
                      }}
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                        border: `2px solid ${theme.palette.secondary.main}`,
                        borderBottom: `4px solid ${theme.palette.secondary.main}`,
                      }}
                    />
                  }
                />
              </div>
              <div>
                <TeamMember
                  name="Placeholder"
                  description="Lora is our office manager, marketing director and all around get it done girl.  You'll likely be talking to her when you call and she can answer all your questions!"
                  title="Crewmember"
                  image={
                    <StaticImage
                      src="../assets/images/310x310.png"
                      alt="Lora of Andreatta Waterscapes"
                      placeholder="blurred"
                      height={310}
                      width={310}
                      imgStyle={{
                        zIndex: "0",
                        border: `4px solid ${theme.palette.secondary.main}`,
                      }}
                    />
                  }
                />
              </div>
            </Slider>
          )} */}
        </div>
      </Grid>
    </Grid>
  )
}
