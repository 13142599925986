import React from "react"
import styled from "styled-components"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import AboutPageComponent from "../components/AboutPage"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
// import SectionSpacer from "../components/UI/SectionSpacer"
// import CallToAction from "../components/CallToAction"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import headerImg from "../assets/images/10-Landscaped-Pondless-Waterfall-Medford-oregon.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 65%;
  color: white;
  padding: 7rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
  @media only screen and (max-width: 600px) {
    backgroundposition: 50% 50%;
    background-attachment: scroll;
  }
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
`
const useStyles = makeStyles(theme => ({
  h1: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 400,
    lineHeight: "1.2",
    letterSpacing: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  subtitle: {
    fontFamily: "Open Sans, sans-serif",
  },
  headerBox: {
    padding: "1.25rem",
    background: `rgba(0,0,0,0.5)`,
    color: "#fff",
    marginTop: "3rem",
    marginBottom: "2rem",
    borderRadius: 0,
    maxWidth: "60%",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      marginLeft: "auto",
    },
  },
}))

export default function AboutPage() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Layout>
      <Navbar />
      <Seo
        title="About Us"
        description="Learn more about pond builder, Andreatta Waterscapes, based out of Central Point Oregon. Specializing in natural style ponds, streams and waterfalls."
      />
      <Background>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Paper className={classes.headerBox} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className={classes.h1}
                    align={matchesXS ? "center" : "left"}
                    gutterBottom
                  >
                    ANDREATTA WATERSCAPES
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    paragraph
                    className={classes.subtitle}
                    align={matchesXS ? "center" : "left"}
                  >
                    Learn a little about us!
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Background>
      <AboutPageComponent />
      {/* <SectionSpacer /> */}

      {/* <CallToAction end={true} /> */}
    </Layout>
  )
}
