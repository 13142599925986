import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import CrewSection from "./Team"

const useStyles = makeStyles(theme => ({
  missionStatement: {
    fontStyle: "italic",
    fontWeight: 300,
    fontSize: "1.5rem",
    maxWidth: "50em",
    lineHeight: 1.4,
  },
  rowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  crewImg: {
    display: "block",
    maxWidth: "41rem",
    // borderTop: `4px solid ${theme.palette.secondary.main}`,
    // borderBottom: `4px solid ${theme.palette.secondary.main}`,
    border: `4px solid ${theme.palette.secondary.main}`,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "3em",
    [theme.breakpoints.down("lg")]: {
      marginTop: "5em",
      maxWidth: "39em",
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "30rem",
      marginBottom: "4em",
      border: `4px solid ${theme.palette.secondary.main}`,
      // borderTop: `1px solid ${theme.palette.secondary.main}`,
      // borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
    },
  },
  ownerImg: {
    display: "flex",
    maxWidth: 400,
    marginLeft: "auto",
    marginRight: "auto",
    border: `4px solid ${theme.palette.secondary.main}`,
    // borderTop: `2px solid ${theme.palette.secondary.main}`,
    // borderBottom: `2px solid ${theme.palette.secondary.main}`,
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      borderTop: `2px solid ${theme.palette.secondary.main}`,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "4em",
      border: `4px solid ${theme.palette.secondary.main}`,
      // borderTop: `1px solid ${theme.palette.secondary.main}`,
      // borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  h2: {
    color: theme.palette.secondary.light,
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  h3: {
    color: theme.palette.secondary.light,
    fontFamily: "Work Sans, sans-serif",
    fontSize: "1.75rem",
    fontWeight: 400,
    marginBottom: ".15em",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.65rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  li: {
    ...theme.typography.body1,
    marginBottom: ".25rem",
  },
  body1: {
    lineHeight: 1.75,
  }
}))

export default function AboutPageComponent() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <>
      <Grid
        container
        direction="row-reverse"
        style={{ paddingTop: matchesXS ? undefined : "5em" }}
      >
        <Grid item xs={12} lg={6}>
          <Grid item>
            <StaticImage
              src="../assets/images/Andreatta Waterscapes_Crew_Gold Hill Oregon.jpg"
              alt="the andreatta waterscapes crew"
              placeholder="blurred"
              layout="constrained"
              quality={80}
              height={800}
              transformOptions={{
                fit: "cover",
              }}
              className={classes.crewImg}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid
            container
            style={{
              width: matchesXS ? "90%" : matchesMD ? "60%" : "80%",
              marginLeft: "auto",
              marginRight: matchesMD ? "auto" : undefined,
            }}
          >
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : undefined}
                    variant="h3"
                    gutterBottom
                    className={classes.h3}
                  >
                    Mission:
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Creating natural spaces to have a positive impact on the
                      people and animals that interact with them. Through
                      quality service and products, we meet or exceed our
                      customers’ expectations.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      align={matchesMD ? "center" : undefined}
                      variant="h4"
                      gutterBottom
                      className={classes.h3}
                      style={{ marginTop: ".75em" }}
                    >
                      Vision:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      xs={11}
                      sm={12}
                      style={{
                        marginLeft: matchesXS ? "auto" : undefined,
                        marginRight: matchesXS ? "auto" : undefined,
                      }}
                    >
                      <Typography
                        variant="body1"
                        align={matchesMD ? "center" : undefined}
                        paragraph
                      >
                        Nurture positive relationships with our customers,
                        employees, and community through our interactions, and
                        cultivate meaning in the relationships we engage in.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      align={matchesMD ? "center" : undefined}
                      variant="h5"
                      className={classes.h3}
                      style={{ marginTop: ".75em" }}
                    >
                      Values:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      xs={11}
                      sm={12}
                      style={{
                        marginLeft: matchesXS ? "auto" : undefined,
                        marginRight: matchesXS ? "auto" : undefined,
                      }}
                    >
                      <ul style={{ fontSize: "1.05rem", marginTop: ".15em" }}>
                        <li className={classes.li}>
                          Two heads are better than one
                        </li>
                        <li className={classes.li}>
                          Do the right thing. Always.
                        </li>
                        <li className={classes.li}>
                          Quality... where OCD shines
                        </li>
                        <li className={classes.li}>Remember to have fun!</li>
                        <li className={classes.li}>
                          Dirty hands are happy hands
                        </li>
                        <li className={classes.li}>Always learning</li>
                        <li className={classes.li}>
                          Family... the reason why we do it!
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Andreatta Waterscapes has been serving the Rogue Valley in
                      Southern Oregon since 1998. While a lot of pond builders
                      and water feature companies have come and gone, Andreatta
                      Waterscapes has remained as the premier water feature
                      business in Southern Oregon.
                    </Typography>
                    <Typography
                      variant="body1"
                      
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Natural style ponds, streams and waterfalls are our only
                      focus and we are experts in our field… and your yard!
                    </Typography>
                    <Typography
                      variant="body1"
                      
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      In addition to building and remodeling water features, we
                      also provide maintenance services, repairs and
                      consultations. To compliment and complete our customers
                      water features, we are also able to install hardscapes
                      such as rock walls, patios and walkways.
                    </Typography>
                    <Typography
                      variant="body1"
                      
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Our company founder and president is Tonja Andreatta.
                      Tonja brings to her work an innate sense of what makes for
                      a natural and aesthetically pleasing water feature, which
                      is the crux of what has made Andreatta Waterscapes the top
                      ranking water feature company in the Rogue Valley for many
                      years.
                    </Typography>
                    <Typography
                      variant="body1"
                      
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      The depths of both technical expertise and artistic vision
                      that Tonja and her crew bring to each project is
                      unmatched, and we're sure you'll be proud to show off your
                      own water feature once we’ve completed it.
                    </Typography>
                  </Grid>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={matchesMD ? "row-reverse" : "row"}
        style={{ paddingTop: "4.675em", paddingBottom: "1.25em" }}
      >
        <Grid item xs={12} lg={5}>
          <Grid
            item
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <StaticImage
              src="../assets/images/Tonja-Andreatta_Owner_Andreatta-Waterscapes_Central-Point-Oregon.jpg"
              alt="Tonja Andreatta, owner of Andreatta Waterscapes"
              placeholder="blurred"
              layout="constrained"
              quality={60}
              className={classes.ownerImg}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid
            container
            style={{
              width: matchesXS ? "90%" : matchesMD ? "60%" : "80%",
              marginRight: "auto",
              marginLeft: matchesMD ? "auto" : undefined,
            }}
          >
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : undefined}
                    variant="h6"
                    gutterBottom
                    className={classes.h2}
                  >
                    Tonja Andreatta
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                      className={classes.body1}
                    >
                      Tonja is a Southern Oregon native and enjoyed spending
                      long summer days on her grandparent’s farm overlooking
                      Emigrant Lake.
                    </Typography>
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                      style={{
                        fontSize: ".95rem",
                        fontStyle: "italic",
                        fontFamily: "Lora, Work Sans, sans-serif",
                      }}
                      className={classes.body1}
                    >
                      “My first fishing was in grandpa’s farm pond catching
                      Bluegill. I spent hours walking along the irrigation
                      ditch, and days hiking the Siskiyou mountains. Grandpa
                      always had a big garden and he helped instill my love of
                      the outdoors. I’ve always said I’m happiest with my
                      fingers in the dirt and a good dog by my side. My first
                      foray into being self-employed was owning a grounds
                      maintenance company. I was completely bored with mowing
                      straight lines in lawns at apartment complexes. It did
                      give me a lot of foundational knowledge, but mostly it
                      gave me a love for working outside. I saw an ad in a
                      landscape magazine offering a workshop on how to build
                      your own pond. I came back excited to give it a try. I
                      gathered up my softball team and coerced them into helping
                      me build it. That’s when my first pond was born, and I
                      knew that was what I was meant to do.”
                    </Typography>
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                      className={classes.body1}
                    >
                      Tonja has developed a style and reputation as an expert in
                      her field and her customers rave about the amazing show
                      pieces their outdoor spaces have become. Thanks to her
                      creativity and knowledge of working with natural
                      materials, she has been able to create the perfect space
                      for each location which her customers are able to enjoy
                      throughout the year. Her love of nature and the
                      environment can be felt as you admire her ponds and
                      waterfalls.
                    </Typography>
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                      style={{
                        fontSize: ".95rem",
                        fontStyle: "italic",
                        fontFamily: "Lora, Work Sans, sans-serif",
                      }}
                      className={classes.body1}
                    >
                      “Some of my fondest childhood memories are climbing trees
                      and playing tag in the pear orchards, hot summer days at
                      the river catching anything that would move (especially
                      lizards), and eating fresh blackberries, plums and
                      cherries. I’ve watched the Rogue Valley’s landscape change
                      as the orchards, fields, and wetlands from my childhood
                      memories vanish. A backyard pond helps keep a connection
                      with nature, soothes your senses, and can be a huge
                      benefit for the wildlife that are losing their natural
                      habitat.”
                    </Typography>
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                      style={{ width: "100%" }}
                      className={classes.body1}
                    >
                      Happy Ponderings!
                      <br />
                      Tonja Andreatta
                      <br />
                      The Pond Lady
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={matchesMD ? "row" : "row-reverse"}
        style={{ paddingTop: "4.675em", paddingBottom: "6.25em" }}
      >
        <Grid item xs={12} lg={5}>
          <Grid
            item
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <StaticImage
              src="../assets/images/Stephanie Belknap_Andreatta Waterscapes_Central Point Oregon.jpeg"
              alt="Stephanie Belknap of Andreatta Waterscapes"
              placeholder="blurred"
              layout="constrained"
              className={classes.ownerImg}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid
            alignItems="center"
            container
            style={{
              width: matchesXS ? "90%" : matchesMD ? "60%" : "80%",
              marginRight: "auto",
              height: "100%",
              marginLeft: matchesMD ? "auto" : undefined,
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                style={{ marginLeft: matchesMD ? undefined : "10rem" }}
              >
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : undefined}
                    variant="h6"
                    gutterBottom
                    className={classes.h2}
                  >
                    Stephanie Belknap
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Stephanie has been with Andreatta Waterscapes since 2016.
                      We are lucky to have such a go getter both in our office
                      and out in the field. She helps keep things running
                      smoothly and you’ll likely talk to her at some point when
                      you call the office.
                    </Typography>
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Running the maintenance routes, doing repairs, and making
                      sure customers have the plants, fish, and products to keep
                      their ponds looking top notch are what make Stephanie such
                      a valuable member of the AW team. Our customers agree, and
                      we’ve heard from quite a few over the years letting us
                      know how much they appreciate her!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={matchesMD ? "row-reverse" : "row"}
        style={{ paddingTop: "4.675em", paddingBottom: "6.25em" }}
      >
        <Grid item xs={12} lg={5}>
          <Grid
            item
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <StaticImage
              src="../assets/images/Lora-Andreatta_Andreatta-Waterscapes_Central-Point-Oregon.jpg"
              alt="Lora Andreatta, owner of Andreatta Waterscapes"
              placeholder="blurred"
              layout="constrained"
              className={classes.ownerImg}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid
            alignItems="center"
            container
            style={{
              width: matchesXS ? "90%" : matchesMD ? "60%" : "80%",
              marginRight: "auto",
              height: "100%",
              marginLeft: matchesMD ? "auto" : undefined,
            }}
          >
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : undefined}
                    variant="h6"
                    gutterBottom
                    className={classes.h2}
                  >
                    Lora Andreatta
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Starting in 2012, Lora has worked in various roles within
                      the company from maintenance technician, office manager
                      and now working primarily with marketing. She plays an
                      integral role behind the scenes and is always there to
                      help keep the company moving forward.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction={matchesMD ? "row" : "row-reverse"}
        style={{ paddingTop: "4.675em", paddingBottom: "6.25em" }}
      >
        <Grid item xs={12} lg={5}>
          <Grid
            item
            style={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            <StaticImage
              src="../assets/images/288x384.png"
              alt="Anthony Grant of Andreatta Waterscapes"
              placeholder="blurred"
              layout="constrained"
              className={classes.ownerImg}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={7}>
          <Grid
            alignItems="center"
            container
            style={{
              width: matchesXS ? "90%" : matchesMD ? "60%" : "80%",
              marginRight: "auto",
              height: "100%",
              marginLeft: matchesMD ? "auto" : undefined,
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                style={{ marginLeft: matchesMD ? undefined : "10rem" }}
              >
                <Grid item>
                  <Typography
                    align={matchesMD ? "center" : undefined}
                    variant="h6"
                    gutterBottom
                    className={classes.h2}
                  >
                    Anthony Grant
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    xs={11}
                    sm={12}
                    style={{
                      marginLeft: matchesXS ? "auto" : undefined,
                      marginRight: matchesXS ? "auto" : undefined,
                    }}
                  >
                    <Typography
                      variant="body1"
                      align={matchesMD ? "center" : undefined}
                      paragraph
                    >
                      Anthony, AW Foreman, brings a great attitude and wealth of
                      skills to our team. He's a great communicator and helps to
                      keep our projects moving forward. We're grateful to have
                      this hard-working and friendly guy on our team!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <CrewSection />
    </>
  )
}
